<template>
  <Navbar />
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
          <UserSidebar />
        </div>
        <div class="col-md-7 col-lg-8 col-xl-9">
          <div class="row">
            <div class="col-md-12 col-lg-4 dash-board-list blue">
              <div class="dash-widget">
                <div class="circle-bar">
                  <div class="icon-col">
                    <i class="fas fa-book"></i>
                  </div>
                </div>
                <div class="dash-widget-info">
                  <h3>{{ coursesCount }}</h3>
                  <h6>Courses</h6>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-lg-4 dash-board-list yellow">
              <div class="dash-widget">
                <div class="circle-bar">
                  <div class="icon-col">
                    <i class="fas fa-users"></i>
                  </div>
                </div>
                <div class="dash-widget-info">
                  <h3>{{ studentsCount }}</h3>
                  <h6>{{ studentsCount != 1 ? "Students" : "Student" }}</h6>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-lg-4 dash-board-list pink">
              <div class="dash-widget">
                <div class="circle-bar">
                  <div class="icon-col">
                    <i class="fas fa-coins"></i>
                  </div>
                </div>
                <div class="dash-widget-info">
                  <h3>{{ formatPrice(totalGain) }} {{ currency }}</h3>
                  <h6>{{ $t("Dashboard.GAIN") }}</h6>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="d-flex justify-content-between">
              <h2>earnings details</h2>
              <button
                :disabled="totalGain < minAmount"
                class="btn btn-success btn-sm"
                @click="paymentRequest"
              >
                Demande de payement
              </button>
            </div>
            <div class="card card-table">
              <div class="card-body">
                <div class="table-responsive">
                  <Datatable
                    :perPage="10"
                    :columns="earningsColumns"
                    :rows="earnings"
                    className="table-hover table-c"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2>Payments requests</h2>
            <div class="card card-table">
              <div class="card-body">
                <div class="table-responsive">
                  <Datatable
                    :perPage="10"
                    :columns="requestsColumns"
                    :rows="requests"
                    className="table-hover table-c"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</template>
<script>
import Navbar from "@/components/layouts/Navbar.vue";
import UserSidebar from "@/components/shared/personal/UserSidebar.vue";
import Datatable from "@/components/shared/tools/Datatable.vue";
import numeral from "numeral";
import jwtDecode from "jwt-decode";
export default {
  components: { Navbar, UserSidebar, Datatable },
  data() {
    return {
      earnings: [""],
      requests: [""],
      earningsColumns: [
        {
          property: "course",
          title: "Course",
          direction: "up",
          filterable: true,
        },
        {
          property: "month",
          title: "Month",
          direction: "up",
          filterable: true,
        },
        {
          property: "year",
          title: "Year",
          direction: "up",
          filterable: true,
        },
        {
          property: "earnedAmount",
          title: "Earned",
          direction: "up",
          filterable: true,
        },
        {
          property: "totalAmount",
          title: "Total",
          direction: "up",
          filterable: true,
        },
        {
          property: "currency",
          title: "Currency",
          direction: "up",
          filterable: true,
        },
        {
          property: "status",
          title: "Status",
          direction: "up",
          filterable: true,
        },
      ],
      requestsColumns: [
        {
          property: "requestDate",
          title: "Request date",
          direction: "up",
          filterable: true,
        },
        {
          property: "amount",
          title: "Amount",
          direction: "up",
          filterable: true,
        },
        {
          property: "currency",
          title: "Currency",
          direction: "up",
          filterable: true,
        },
        {
          property: "status",
          title: "Status",
          direction: "up",
          filterable: true,
        },
        {
          property: "paymentDate",
          title: "Payment date",
          direction: "up",
          filterable: true,
        },
      ],
      coursesCount: null,
      totalGain: 0,
      minAmount: 2000,
      studentsCount: 0,
    };
  },
  methods: {
    async loadEanings() {
      await this.$http
        .get("/auth/teacher/total-list-of-gain")
        .then((response) => {
          this.earnings = response.data;
          this.getTotalGain();
          this.loadPaymentsRequests();
        });
    },
    async loadCounts() {
      const token = localStorage.getItem("access");
      if (token !== null) {
        if (this.coursesCount === null) {
          await this.$http
            .get(`/auth/teacher/course-list/${jwtDecode(token).user_id}/`)
            .then((response) => {
              this.coursesCount = response.data.length;
              response.data.forEach((c) => {
                this.studentsCount += c.studentsNumber;
              });

              this.getTotalGain();
            });
        }
      }
    },
    formatPrice(p) {
      return numeral(p).format("0,0");
    },
    async loadPaymentsRequests() {
      await this.$http
        .get("/auth/teacher/request-payment-list")
        .then((response) => {
          console.log(response.data);

          this.requests = response.data.map((value) => {
            return {
              ...value,
              paymentDate:
                value.status == "PAID"
                  ? new Date(value.paymentDate).toLocaleString().substring(0, 9)
                  : "",
              requestDate: new Date(value.requestDate)
                .toLocaleString()
                .substring(0, 9),
            };
          });
        });
    },
    async paymentRequest() {
      await this.$http.post("/auth/teacher/payment-request").then(() => {
        this.$toast.success(this.$t("SUCCESS"));
        this.loadEanings();
        this.loadPaymentsRequests();
      });
    },
    async getTotalGain() {
      await this.$http.get("/auth/teacher/total-gain").then((response) => {
        this.totalGain = response.data.amount;
        this.minAmount = response.data.minAmount;
        this.currency = response.data.currency;
      });
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.loadEanings();
      this.loadCounts();
    });
  },
};
</script>
<style lang=""></style>
